import { message } from "antd";
import { useEffect, useState } from "react";
import useRegisterNfcUsb from "src/hooks/useRegisterNfcUsb";

import Container from "@app-components/demo/Container";
import FloatActions from "@app-components/demo/FloatActions";
import DisplayResult from "@app-components/demo/Result";
import Welcome from "@app-components/demo/Welcome";
import Loading from "@app-components/layout/Loading";

export default function DemoDashboard() {
  const { uidList, resetUidList } = useRegisterNfcUsb();
  const [loading, setLoading] = useState(false);
  const [showResult, setShowResult] = useState(false);

  const [currentIdIndex, setCurrentIdIndex] = useState<number>(0);

  useEffect(() => {
    if (
      uidList.length &&
      uidList.length > currentIdIndex &&
      !loading &&
      !showResult
    ) {
      setCurrentIdIndex(uidList.length);
      handleOnConfirm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uidList]);

  useEffect(() => {
    return () => {
      setLoading(false);
      setShowResult(false);
      setCurrentIdIndex(0);
      resetUidList();
    };
  }, []);

  const handleOnConfirm = async () => {
    setLoading(true);
    setTimeout(() => {
      setShowResult(true);
      setLoading(false);
      message.success("Payment successful");
    }, 1600);
  };

  const goBack = () => {
    setShowResult(false);
  };

  const newTransaction = () => {
    setLoading(true);
    setTimeout(() => {
      setShowResult(false);
      setLoading(false);
    }, 300);
  };

  return (
    <>
      {!showResult && (
        <Container bgDark>
          <Welcome
            title={`Welcome to \nThe Ring Payment Experience`}
            subtitle="Tap The Ring on the Payment Terminal to Pay"
          />

          {loading && <Loading />}
        </Container>
      )}
      {showResult && (
        <DisplayResult
          onNewTransaction={newTransaction}
          subtitle={"Payment Succesfull"}
        />
      )}
      <FloatActions onReload={goBack} />
      {loading && <Loading />}
    </>
  );
}
