import RingBgImage from "@app-assets/images/ring-bg.png";
import MTLogoSvg from "@app-components/svg/MTLogoSvg";

export default function Container({
  children,
  bgDark,
  bgImage,
}: {
  children: any;
  bgDark?: boolean;
  bgImage?: boolean;
}) {
  return (
    <div
      style={{
        backgroundImage: bgImage ? `url(${RingBgImage})` : "",
      }}
      className={
        bgDark
          ? "min-h-screen flex relative bg-black bg-cover bg-center"
          : "flex flex-col min-h-screen bg-white"
      }
    >
      {!bgDark && (
        <div className="sticky top-0 right-0 left-0 h-[100px] flex justify-center items-center w-full bg-silver-100">
          <MTLogoSvg color="#111318" />
        </div>
      )}
      <div className=" w-full flex flex-col justify-center items-center container mx-auto">
        {children}
      </div>
    </div>
  );
}
