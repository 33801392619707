import { ArrowDownOutlined } from "@ant-design/icons";
import { useSetAtom } from "jotai";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { permissionKeyAtom, useAuthWriteOnly } from "@app-jotai/auth";

export default function FloatActions({
  onReload,
  onLeftAction,
  leftActionTitle,
}: {
  onReload: () => void;
  onLeftAction?: () => void;
  leftActionTitle?: string;
}) {
  const navigate = useNavigate();
  const setPermissionKey = useSetAtom(permissionKeyAtom);
  const { onSignout } = useAuthWriteOnly();
  const [toggle, setToggle] = useState(false);

  const handleOnSignout = () => {
    setPermissionKey("");
    onSignout();
    navigate("/login");
  };

  return (
    <>
      <div className="fixed bottom-8 left-8 flex flex-col gap-4 transition-all text-gray-500 font-semibold">
        <div className="" onClick={() => setToggle((prev) => !prev)}>
          <ArrowDownOutlined className=" p-2 cursor-pointer rounded-full bg-gray-200 text-[20px] text-gray-500" />
        </div>
        {toggle && (
          <>
            {onLeftAction && (
              <div onClick={onLeftAction} className="hover:cursor-pointer py-2">
                {leftActionTitle}
              </div>
            )}
            <div
              className="hover:cursor-pointer py-2"
              onClick={handleOnSignout}
            >
              Signout
            </div>
            <div onClick={onReload} className="hover:cursor-pointer py-2">
              Reload
            </div>
          </>
        )}
      </div>
    </>
  );
}
