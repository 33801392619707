import HeroImage from "@app-assets/images/hero.png";

import NFCSvg from "../svg/NFCSvg";

export default function Welcome({
  title,
  subtitle,
}: {
  title: string;
  subtitle: string;
}) {
  return (
    <div className="flex flex-1 flex-col md:flex-row justify-center items-center w-full">
      <div className="w-full md:w-1/2 md:pt-[126px] order-1 md:order-0 ">
        <img
          className="absolute w-full top-auto -right-10 inset-0 md:right-auto m-auto"
          src={HeroImage}
          alt="hero"
        />
      </div>
      <div className="w-full md:w-1/2 py-[40px] flex items-center flex-col order-0 md:order-1 md:pr-[36px] z-30">
        <div className="flex flex-col justify-center items-center w-full px-8">
          <div className="text-white text-[64px] text-center font-[700] whitespace-pre-line">
            {title}
          </div>
          <div className="pt-[72px] pb-[32px]">
            <NFCSvg color="white" />
          </div>
          <div className="text-white text-[32px] text-center">{subtitle}</div>
        </div>
      </div>
    </div>
  );
}
