import CheckSolid from "@app-components/svg/CheckSolid";

import Container from "./Container";

export default function DisplayResult({
  title,
  subtitle,
  children,
  onNewTransaction,
}: {
  title?: any;
  subtitle?: any;
  children?: any;
  onNewTransaction: () => void;
}) {
  return (
    <Container bgImage bgDark>
      <div className="w-full flex items-center justify-center flex-col text-white">
        <div className="flex flex-col justify-center items-center min-w-[400px]">
          <div className="flex-1 flex justify-end">
            <CheckSolid color="#0ABD25" className="w-[52px] h-[52px]" />
          </div>
          <div className="text-[36px] font-semibold mb-4 text-center w-4/5 md:w-2/3">
            {title}
          </div>
          {subtitle && (
            <div className="text-[32px] text-center w-[90%] md:w-[80%]">
              {subtitle}
            </div>
          )}

          <div
            onClick={onNewTransaction}
            className="text-[32px] hover:underline cursor-pointer font-semibold mt-[47px] py-[24px] px-[16px] max-w-[792px] w-full text-center rounded-md bg-[#FCFBFC1A]"
          >
            New transation
          </div>
          {children}
        </div>
      </div>
    </Container>
  );
}
