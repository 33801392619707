import Lottie from "lottie-react";

import animationData from "@app-assets/lotties/loading.json";

export default function Loading() {
  return (
    <div className="w-screen h-screen bg-black fixed inset-0 z-[1000] flex justify-center items-center transition-all">
      <div className="flex flex-col gap-[46px] justify-center items-center">
        <Lottie animationData={animationData} autoPlay loop />
        <div className="text-[32px] text-white">Just a moment...</div>
      </div>
    </div>
  );
}
